import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import KansouBuhin from '@components/md-components/elements-n/kansou-buhin.md';
import Gotya from '@components/md-components/elements-n/rebyu/gotya.md';
import Syougeki from '@components/md-components/elements-n/rebyu/syougeki.md';
import Iutori from '@components/md-components/elements-n/rebyu/iutori.md';
import Huan from '@components/md-components/elements-n/rebyu/huan.md';
import Tanosi from '@components/md-components/elements-n/rebyu/tanosi.md';
import Tiisasa from '@components/md-components/elements-n/rebyu/tiisasa.md';
import Saku from '@components/md-components/elements-n/rebyu/saku.md';
import Itiban from '@components/md-components/elements-n/rebyu/itiban.md';
import Unari from '@components/md-components/elements-n/rebyu/unari.md';
import Numa from '@components/md-components/elements-n/rebyu/numa.md';
import Kanpeki from '@components/md-components/elements-n/rebyu/kanpeki.md';
import Yougo from '@components/md-components/elements-n/rebyu/yougo.md';
import Jugyo from '@components/md-components/elements-n/rebyu/jugyo.md';
import Kando from '@components/md-components/elements-n/rebyu/kando.md';
import Ee from '@components/md-components/elements-n/rebyu/ee.md';
import Saiteigen from '@components/md-components/elements-n/rebyu/saiteigen.md';
import Omosiroi from '@components/md-components/elements-n/rebyu/omosiroi.md';
import Uroko from '@components/md-components/elements-n/rebyu/uroko.md';
import Sinkin from '@components/md-components/elements-n/rebyu/sinkin.md';
import Osorosiku from '@components/md-components/elements-n/rebyu/osorosiku.md';
import Tensyon from '@components/md-components/elements-n/rebyu/tensyon.md';
import Wakariyasusugi from '@components/md-components/elements-n/rebyu/wakariyasusugi.md';
import Husigito from '@components/md-components/elements-n/rebyu/husigito.md';
import Daredemo from '@components/md-components/elements-n/rebyu/Daredemo.md';
import Iken from '@components/md-components/elements-n/rebyu/iken.md';
import Mayou from '@components/md-components/elements-n/rebyu/mayou.md';
import Sugoi from '@components/md-components/elements-n/rebyu/sugoi.md';
import Hirome from '@components/md-components/elements-n/rebyu/hirome.md';
import Saikin from '@components/md-components/elements-n/rebyu/saikin.md';
import Tyugaku from '@components/md-components/elements-n/rebyu/tyugaku.md';
import Tukin from '@components/md-components/elements-n/rebyu/tukin.md';
import Namida from '@components/md-components/elements-n/rebyu/namida.md';
import Kai from '@components/md-components/elements-n/rebyu/kai.md';
import Ratio from '@components/md-components/elements-n/rebyu/ratio.md';
import Ippo from '@components/md-components/elements-n/rebyu/ippo.md';
import Kuku from '@components/md-components/elements-n/rebyu/kuku.md';
import Kemu from '@components/md-components/elements-n/rebyu/kemu.md';
import Ratio2 from '@components/md-components/elements-n/rebyu/ratio2.md';
import Tuika from '@components/md-components/elements-n/rebyu/tuika.md';
import Sukkiri from '@components/md-components/elements-n/rebyu/sukkiri.md';
import Jujitu from '@components/md-components/elements-n/rebyu/jujitu.md';
import Tesaguri from '@components/md-components/elements-n/rebyu/tesaguri.md';
import Yougo2 from '@components/md-components/elements-n/rebyu/yougo2.md';
import Sokudo from '@components/md-components/elements-n/rebyu/sokudo.md';
import Kantan from '@components/md-components/elements-n/rebyu/kantan.md';
import Kumo from '@components/md-components/elements-n/rebyu/kumo.md';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <section className="light">
      <h2>レビュー</h2>
      <KansouBuhin mdxType="KansouBuhin" />
      <Husigito mdxType="Husigito" />
      <Gotya mdxType="Gotya" />
      <Kuku mdxType="Kuku" />
      <Syougeki mdxType="Syougeki" />
      <Daredemo mdxType="Daredemo" />
      <Kemu mdxType="Kemu" />
      <Tanosi mdxType="Tanosi" />
      <Uroko mdxType="Uroko" />
      <Kantan mdxType="Kantan" />
      <Namida mdxType="Namida" />
      <Sokudo mdxType="Sokudo" />
      <Hirome mdxType="Hirome" />
      <Iutori mdxType="Iutori" />
      <Wakariyasusugi mdxType="Wakariyasusugi" />
      <Ratio mdxType="Ratio" />
      <Numa mdxType="Numa" />
      <Osorosiku mdxType="Osorosiku" />
      <Omosiroi mdxType="Omosiroi" />
      <Kai mdxType="Kai" />
      <Tyugaku mdxType="Tyugaku" />
      <Tesaguri mdxType="Tesaguri" />
      <Tensyon mdxType="Tensyon" />
      <Jujitu mdxType="Jujitu" />
      <Ee mdxType="Ee" />
      <Yougo2 mdxType="Yougo2" />
      <Tukin mdxType="Tukin" />
      <Ratio2 mdxType="Ratio2" />
      <Ippo mdxType="Ippo" />
      <Kumo mdxType="Kumo" />
      <Sugoi mdxType="Sugoi" />
      <Sinkin mdxType="Sinkin" />
    </section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      