import React from "react"
import { Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"

import SEO from '@components/seo'
import Helmet from 'react-helmet'

import H2Back from '@components/h2-back'
import GazouMain from '@components/gazou-main'

import Intro from '@components/md-components/elements-n/intro.md'
import Dekirumade from '@components/md-components/elements-n/dekirumade.md'
import Toha from '@components/md-components/elements-n/toha.md'
import Tuyomi from '@components/md-components/elements-n/tuyomi.md'
import Muti from '@components/md-components/elements-n/muti.md'
import Tokutyou from '@components/md-components/elements-n/tokutyou.md'
import Naiyou from '@components/md-components/elements-n/naiyou.md'
import Muiteru from '@components/md-components/elements-n/muiteru.md'
import Tokuten from '@components/md-components/elements-n/tokuten.md'
import RebyuR from '@components/md-components/elements-n/rebyu-r.md'
import Kakaku from '@components/md-components/elements-n/kakaku.md'
import SiharaiHouhou from '@components/md-components/elements-n/siharai-houhou.md'
import Situmon from '@components/md-components/elements-n/situmon.md'
import Sime from '@components/md-components/elements-n/sime.md'
import Siharai from '@components/siharai'

import Products from '@layouts/products'


class Saihan extends React.Component {

	render() {
		return (

			<>

				<SEO title="ELEMENTS 必須知識編" />
				<Helmet>
					<noscript>
						{`<meta http-equiv="Refresh" content="0; URL=https://elvn.tokyo/osirase/noscript/"/>`}
					</noscript>
				</Helmet>

				<div id="count_down" class='t-center'>
					<p>限定版の終了まで、あと
				<span id="CDT"><span class="day"></span>
							<span class="hour"></span>
							<span class="min"></span>
							<span class="sec"></span>
							<span class="milli"></span></span>
					</p>
				</div>

				<img id='elements_n_hero' class="lazyload" alt=""
					src="https://elvn.imgix.net/products/elements-n.jpg?fit=crop&w=400&auto=format,compress&q=0"
					data-src="https://elvn.imgix.net/products/elements-n.jpg?fit=crop&w=1927&auto=format&fm=jpg&q=35&cs=srgb"
					data-srcset="https://elvn.imgix.net/products/elements-n.jpg?fit=crop&w=1360&h=1200&auto=format&fm=jpg&q=35&cs=srgb 1242w,
						https://elvn.imgix.net/products/elements-n.jpg?fit=crop&w=1927&auto=format&fm=jpg&q=35&cs=srgb 1668w,
						https://elvn.imgix.net/products/elements-n.jpg?fit=crop&w=2833&h=1200&auto=format&fm=jpg&q=45&cs=srgb 1669w"
				/>

				<Products>

					<MDXProvider
						components={{
							h3: props => <h3>{props.children}<H2Back /></h3>,
						}}
					>
						<Intro />
						<img class="lazyload products_img" alt=""
							src="https://elvn.imgix.net/products/blue.jpg?fit=crop&w=400&auto=format,compress&q=0"
							data-src="hhttps://elvn.imgix.net/products/blue.jpg?fit=crop&w=1927&auto=format&q=35&cs=srgb"
							data-srcset="https://elvn.imgix.net/products/blue.jpg?fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1242w,
						https://elvn.imgix.net/products/blue.jpg?fit=crop&w=1927&auto=format&q=35&cs=srgb 1668w,
						https://elvn.imgix.net/products/blue.jpg?fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1669w"
						/>

						<Dekirumade />
						<img class="lazyload products_img" alt=""
							src="https://elvn.imgix.net/products/wa.jpg?fit=crop&w=400&auto=format,compress&q=0"
							data-src="hhttps://elvn.imgix.net/products/wa.jpg?fit=crop&w=1927&auto=format&q=35&cs=srgb"
							data-srcset="https://elvn.imgix.net/products/wa.jpg?fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1242w,
						https://elvn.imgix.net/products/wa.jpg?fit=crop&w=1927&auto=format&q=35&cs=srgb 1668w,
						https://elvn.imgix.net/products/wa.jpg?fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1669w"
						/>

						<Toha />
						<img class="lazyload products_img" alt=""
							src="https://elvn.imgix.net/products/ityou.jpg?fit=crop&w=400&auto=format,compress&q=0"
							data-src="hhttps://elvn.imgix.net/products/ityou.jpg?fit=crop&w=1927&auto=format&q=35&cs=srgb"
							data-srcset="https://elvn.imgix.net/products/ityou.jpg?fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1242w,
						https://elvn.imgix.net/products/ityou.jpg?fit=crop&w=1927&auto=format&q=35&cs=srgb 1668w,
						https://elvn.imgix.net/products/ityou.jpg?fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1669w"
						/>

						<Tuyomi />
						<img class="lazyload products_img" alt=""
							src="https://elvn.imgix.net/products/hi2.jpg?con=40&fit=crop&w=400&auto=format,compress&q=0"
							data-src="hhttps://elvn.imgix.net/products/hi2.jpg?con=40&fit=crop&w=1927&auto=format&q=35&cs=srgb"
							data-srcset="https://elvn.imgix.net/products/hi2.jpg?con=40&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1242w,
						https://elvn.imgix.net/products/hi2.jpg?con=40&fit=crop&w=1927&auto=format&q=35&cs=srgb 1668w,
						https://elvn.imgix.net/products/hi2.jpg?con=40&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1669w"
						/>

						<Muti />
						<img class="lazyload products_img" alt=""
							src="https://elvn.imgix.net/products/kaku.jpg?con=40&fit=crop&w=400&auto=format,compress&q=0"
							data-src="hhttps://elvn.imgix.net/products/kaku.jpg?con=40&fit=crop&w=1927&auto=format&q=35&cs=srgb"
							data-srcset="https://elvn.imgix.net/products/kaku.jpg?con=40&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1242w,
						https://elvn.imgix.net/products/kaku.jpg?con=40&fit=crop&w=1927&auto=format&q=35&cs=srgb 1668w,
						https://elvn.imgix.net/products/kaku.jpg?con=40&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1669w"
						/>

						<Tokutyou />
						<Naiyou />
						<img class="lazyload products_img" alt=""
							src="https://elvn.imgix.net/products/ana.jpg?con=40&gam=-15&fit=crop&w=400&auto=format,compress&q=0"
							data-src="hhttps://elvn.imgix.net/products/ana.jpg?con=40&gam=-15&fit=crop&w=1927&auto=format&q=35&cs=srgb"
							data-srcset="https://elvn.imgix.net/products/ana.jpg?con=40&gam=-15&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1242w,
						https://elvn.imgix.net/products/ana.jpg?con=40&gam=-15&fit=crop&w=1927&auto=format&q=35&cs=srgb 1668w,
						https://elvn.imgix.net/products/ana.jpg?con=40&gam=-15&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1669w"
						/>

						<Muiteru />

					</MDXProvider>

					<Tokuten />

					<MDXProvider
						components={{
							h3: props => <h3>{props.children}<H2Back /></h3>,
						}}
					>
						<RebyuR />
						<img class="lazyload products_img" alt=""
							src="https://elvn.imgix.net/products/tokei.jpg?con=40&fit=crop&w=400&auto=format,compress&q=0"
							data-src="hhttps://elvn.imgix.net/products/tokei.jpg?con=40&fit=crop&w=1927&auto=format&q=35&cs=srgb"
							data-srcset="https://elvn.imgix.net/products/tokei.jpg?con=40&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1242w,
						https://elvn.imgix.net/products/tokei.jpg?con=40&fit=crop&w=1927&auto=format&q=35&cs=srgb 1668w,
						https://elvn.imgix.net/products/tokei.jpg?con=40&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1669w"
						/>

						<section class="light">

							<Kakaku />
							<SiharaiHouhou />
							<Situmon />

						</section>

					</MDXProvider>

					<img class="lazyload products_img" alt=""
						src="https://elvn.imgix.net/products/ao.jpg?con=40&fit=crop&w=400&auto=format,compress&q=0"
						data-src="hhttps://elvn.imgix.net/products/ao.jpg?con=40&fit=crop&w=1927&auto=format&q=35&cs=srgb"
						data-srcset="https://elvn.imgix.net/products/ao.jpg?con=40&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1242w,
						https://elvn.imgix.net/products/ao.jpg?con=40&fit=crop&w=1927&auto=format&q=35&cs=srgb 1668w,
						https://elvn.imgix.net/products/ao.jpg?con=40&fit=crop&w=1360&h=1200&auto=format&q=35&cs=srgb 1669w"
					/>

					<Sime />
					<Siharai />

				</Products>
			</>
		)
	}

	componentDidMount() {

		var today = new Date();

		// クッキー「endDate」が空の時
		if (GetCookie('endDate909') == null) {
			// クッキーの終了日時（48時間）
			var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), today.getHours() + 48, today.getMinutes() + 0);
			// クッキーの有効期限（６ヶ月）
			var cookieExpire = new Date(today.getFullYear(), today.getMonth() + 6, today.getDate());
			// 終了日時と有効期限を設定したクッキーを作成
			document.cookie = 'endDate909=' + endDate + '; expires=' + cookieExpire.toUTCString();
		}

		var kigen = new Date(GetCookie('endDate909'));
		var keisan = kigen - today;

		// 現在日時が終了日時を超えている時
		if (keisan <= 0) {
			window.location.replace("../end/");
		}

		// クッキーを取得する機能
		function GetCookie(name) {
			var result = null;
			var cookieName = name + '=';
			var cookies = document.cookie;
			var position = cookies.indexOf(cookieName);

			if (position != -1) {
				var startIndex = position + cookieName.length;
				var endIndex = cookies.indexOf(';', startIndex);
				if (endIndex == -1) { endIndex = cookies.length; }
				result = decodeURIComponent(cookies.substring(startIndex, endIndex));
			}
			return result;
		}

		// カウントダウン機能の宣言
		function CountdownTimer(elm, tl, mes) {
			this.initialize.apply(this, arguments);
		}

		CountdownTimer.prototype = {
			// 変数宣言
			initialize: function (elm, tl, mes) {
				// タイマーを表示する要素のID
				this.elem = document.getElementById(elm);
				// タイムリミット（終了日時）
				this.tl = tl;
				// 表示用メッセージ
				this.mes = mes;
			},
			// カウントダウン機能の中枢
			countDown: function () {
				// 変数宣言
				var timer = '';
				today = new Date();
				var day = Math.floor((this.tl - today) / (24 * 60 * 60 * 1000));
				var hour = Math.floor(((this.tl - today) % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
				var min = Math.floor(((this.tl - today) % (24 * 60 * 60 * 1000)) / (60 * 1000)) % 60;
				var sec = Math.floor(((this.tl - today) % (24 * 60 * 60 * 1000)) / 1000) % 60 % 60;
				var milli = Math.floor(((this.tl - today) % (24 * 60 * 60 * 1000)) / 10) % 100;
				var me = this;

				// 終了日時まで、時間が残っている時
				if ((this.tl - today) > 0) {
					if (day) timer += '<span class="day">' + day + '日</span>';
					if (hour) timer += '<span class="hour">' + hour + '時間</span>';
					timer += '<span class="min">' + this.addZero(min) + '分</span><span class="sec">' + this.addZero(sec) + '秒</span><span class="milli">' + this.addZero(milli) + '</span>';
					this.elem.innerHTML = timer;
					var tid = setTimeout(function () { me.countDown(); }, 10);
				}
				// 終了日時までの残り時間が０以下の時
				else {
					this.elem.innerHTML = this.mes;
					return;
				}

			}, addZero: function (num) { return ('0' + num).slice(-2); }
		}


		function CDT() {
			// tlにタイムリミット（終了日時）を設定
			var tl = new Date(GetCookie('endDate909'));
			// カウントダウン機能に、「要素ID」「終了日時」「表示メッセージ」の３引数を設定
			var timer = new CountdownTimer('CDT', tl, '終了しました');
			// カウントダウン機能中枢を開始
			timer.countDown();
		}

			CDT();
	}
}

export default Saihan